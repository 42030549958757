const initTextHome = () => {
  const block = document.querySelector(".block-text-picture-home");
  const text = document.querySelectorAll(".block-text-picture-home p");
  const leftFlag = block.querySelectorAll(".picture-text__flag");
  const rightFlag = block.querySelectorAll(".picture-text__huge-flag");
  const picture = block.querySelector("figure img");
  const title = block.querySelector(".picture-text__content h2");

  if (title) {
    gsap.from(title, {
      opacity: 0,
      y: "100px",
      scrollTrigger: {
        trigger: title,
        start: "top 90%",
        end: "bottom bottom",
        toggleActions: "play none none reverse",
        once: true,
      },
    });
  }

  if (text) {
    text.forEach((span, index) => {
      gsap.from(span, {
        opacity: 0,
        y: "100px",
        delay: index / 10,
        scrollTrigger: {
          trigger: span,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      });
    });
  }

  function setupParallax(element, speed) {
    gsap.to(element, {
      y: () => `-${window.innerHeight * speed}`,
      ease: "none",
      scrollTrigger: {
        trigger: element,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }

  const flagSpeed = 0.1; // Швидкість для flag
  const iconSpeed = 0.05; // Швидкість для icon

  setupParallax(leftFlag, flagSpeed);

  setupParallax(rightFlag, iconSpeed);
};

document.addEventListener("DOMContentLoaded", function () {
  initTextHome();
});
